import React from 'react'
import { graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { Link } from 'gatsby'
import { IndexQueryQuery, PostByPathQuery } from '../../../types/graphql-types'
import Meta from 'components/meta/meta'
import Layout from 'components/layout/layout'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import Video from 'components/video'
import BackgroundImage from 'gatsby-background-image'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const Cosmetic2Page: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const pd_cta_background = data.pd_cta_background?.childImageSharp?.fluid
  const product1 = data.product1?.childImageSharp?.fluid
  const father_and_daughter_brushing_teeth_in_bathroom = data.father_and_daughter_brushing_teeth_in_bathroom?.childImageSharp?.fluid
  const close_up_of_woman_using_dental_floss_from_pack_in_bathroom =data.close_up_of_woman_using_dental_floss_from_pack_in_bathroom?.childImageSharp?.fluid

  return (
    <Layout location={location}>
      <Meta site={meta} title="Cosmetic Dentistry" />
      <BackgroundImage
        Tag="section"
        className="hero-section-bg"
        fluid={hero_background}
        backgroundColor={`#e9f4f8`}
      >
        <div className ="col-lg-12" id ="color-overlay">
        </div>
        <div id="hero-section">
          <Container>
            <div className="col-lg-12 text-center">
              <p className="hero-text">
                COSMETIC DENTAL SERVICES MCCORDSVILLE
              </p>
              <h1 className="text-light">
                Cosmetic Dentistry
              </h1>
            </div>
            <div className="col-lg-12 text-center">
              <p className="hero-text text-light">We offer many types of preventative dentistry services to help you keep that smile looking bright and healthy. Browse the page below to learn more. It’s easy to schedule your appointment with us.</p>
              <p className="hero-text text-light">Just click “schedule now” as soon as you’re ready.</p>
            </div>
            <div className="col-lg-12 text-center">
              <button className="btn-rounded"> Learn More </button>
              <a href="https://flexbook.me/tdagc/website" target="_blank"><button className="btn-rounded"> Schedule Now </button></a>
            </div>
          </Container>
          </div>
      </BackgroundImage>
      <section id ="pd-section-1" className="page-section text-center">
          <Container>
          <div className="col-lg-12">
            <p className ="section-1-text">RESTORATIVE DENTAL CARE</p>
            <h2 className ="section-2-header">What Are The Benefits Of Restorative Dentistry?</h2>
            <p>Sometimes your smile shows can become damaged. Whether it is from lack of prevention or something out of your control we can help you get back to normal. As you age teeth start to wear and old dental work can fail. Your teeth and your bit can even start to move adversely. Typically, this is where restorative dentistry is the solution.</p>
          </div>
        </Container>
        <Container>
          <div className="col-lg-6">
              <div className="col-md-6">
                <i className="fa fa-bolt" aria-hidden="true"></i>
                <h3 className ="section-1-header">Better Pay & Benefits</h3>
                <p>Your appearance is everything and so is your smile. Having a healthy mouth full of strong white teeth leads to more opportunity and higher pay.</p>
              </div>
              <div className="col-md-6">
                <i className="fa fa-diamond" aria-hidden="true"></i>
                <h3 className ="section-1-header">Great First Impressions</h3>
                <p>The first thing a person sees when they look at a smiling face is teeth! If your teeth are less than attractive, then you're sending a bad first impression.</p>
              </div>
                <div className="col-md-6">
                  <i className="fa fa-bookmark-o" aria-hidden="true"></i>
                  <h3 className ="section-1-header">Boosts Your Self Esteem</h3>
                  <p>You know what it feels like to wear a brand new pair of shoes. Having a great smile will give you a similar feeling.</p>
                </div>
            </div>
            <div className="col-lg-6">
              <Img fluid = {father_and_daughter_brushing_teeth_in_bathroom} />
            </div>
          </Container>
          <Container>
            <div className =" col-lg-12"><p></p></div>
          </Container>
          <Container>
          <div className="col-lg-6">
            <Img fluid = {close_up_of_woman_using_dental_floss_from_pack_in_bathroom} />
          </div>
          <div className = "col-lg-6">
            <div>
              <i className="fa fa-bolt" aria-hidden="true"></i>
              <h3 className ="section-1-header">Improves Overall Dental Health</h3>
              <p>When you put time and money into your teeth, you will take better care of them. Maintaining a great smile is just as important as restoring one.</p>
            </div>
            <div>
              <i className="fa fa-diamond" aria-hidden="true"></i>
              <h3 className ="section-1-header">Smile More & Live Longer</h3>
              <p>Studies have shown that people who smile live longer happier lives. Are your teeth keeping you from smiling?</p>
            </div>
            <div>
              <i className="fa fa-bookmark-o" aria-hidden="true"></i>
              <h3 className ="section-1-header">High Trust & Productivity</h3>
              <p>A smiling person is considered more trustworthy than a person who doesn't. If you're worrying about your teeth then you're not focusing on the important things in life.</p>
            </div>
          </div>
        </Container>
        </section>
        <BackgroundImage
          Tag="section"
          className="pdsection2-bg"
          fluid={pd_cta_background}
          backgroundColor={`#e9f4f8`}
          alt="the dentists at crossroad"
        >
          <div className ="col-lg-12" id ="color-overlay">
          </div>
          <section id="pdsection-2" className="page-section">
            <Container>
              <div className="col-lg-6">
                <p className="hero-text">
                LOVE YOUR SMILE!
                </p>
                <h3 className="text-light">
                Don't miss your chance to live life with the smile you've always wanted!
                </h3>
                <p className="hero-text text-light">
                Stop delaying your life. You’re missing out on so many opportunities by not fixing your teeth! Call TODAY!
                </p>
                <a href="tel:3176439434" className="text-left"><button className="btn-rounded"><i className="fa fa-phone"></i> (317) 643-9434 </button></a>
              </div>
            </Container>
            </section>
          </BackgroundImage>
          <section id ="pd-section-3" className="page-section">
          <Container>
            <div className="col-lg-12 text-center">
              <p className ="section-1-text">COSMETIC DENTAL DETAILS</p>
              <h2 className ="section-3-header">What is Cosmetic Dentistry ?</h2>
              <p>Cosmetic dentistry is a technique of professional oral care that is focused on the improvement of your teeth, gums, and complete smile.</p>
              <p>Cosmetic dentistry procedures can give you the smile you’ve been dreaming of, helping to improve self-confidence. Cosmetic dentistry can also offer some therapeutic benefits, such as making regular dental cleaning easier and even reducing bruxism (grinding).</p>
              <p>Through cosmetic dentistry, Dr. Vogt administers life-changing treatments that will enhance the appearance of your smile.</p>
            </div>
          </Container>
          <Container>
            <div className="col-lg-12 text-left">
              <div className="columns">
                <h3 className ="section-3-header">Is Cosmetic Dentistry Right For You?</h3>
                <p>If the appearance of your teeth prevents you from looking and feeling your best, then cosmetic dentistry may be the perfect solution for you.</p>
                <p>Missing teeth, cracked and chipped teeth, and decayed teeth hold many people back from personal and professional social situations. It is important to look in the mirror and love the person smiling back. This can be accomplished with state of the art cosmetic dental procedures.</p>
                <p>If you’ve been thinking about cosmetic dental enhancements, you’re not alone! Millions of dental patients across the globe seek this type of dentistry and we’re here to help you improve your smile too!</p>
              </div>
            </div>
            <div className="col-lg-12 text-left">
              <div className="columns">
                <h3 className ="section-3-header">Our Cosmetic Dentistry Services List</h3>
                <p>Our cosmetic dentistry services incorporate teeth whitening, porcelain veneers, cosmetic bonding, Invisalign, and complete smile makeovers.</p>
                <h4 className ="section-3-header">Click the "+" Below To Expand Any Service</h4>
                <div className="accordion">
                  <details open>
                    <summary aria-describedby="Check-ups and Teeth Cleaning:">Crowns and Bridges:</summary>
                    <div className="accordion-content">
                      <p>Are you thinking of replacing a missing tooth? With our dental crown and bridges therapy, you’re covered. A bridge can replace about one to three missing teeth in a row given that some teeth still exist on either side of the gap. Dr. Vogt will fit you for a custom-made bridge, and after that, you’ll return to our office to have the new piece placed.</p>
                      <p>We’ll firmly attach the bridge and use crowns to bond it to the adjacent teeth. The whole process is safe, easy, and it will help to return confidence to your smile.</p>
                      <p></p>
                      <p>Alternatively, we have single crowns which can be used to treat dental injuries, severe tooth decay and more.</p>
                    </div>
                  </details>
                  <details>
                    <summary aria-describedby="Oral Cancer Screening:">Teeth Whitening:</summary>
                    <div className="accordion-content">
                      <p>One of the most desired physical traits is a bright smile. If you’re looking to whiten your teeth, our team has the perfect solution for you. We offer excellent Opalescence Go and KOR professional whitening techniques and solutions, which are effective in whitening teeth up to 10 shades. We provide both at-home and in-office professional whitening services, which will leave you with a beautiful white smile you’ll be excited to show off.</p>
                    </div>
                  </details>
                  <details>
                    <summary aria-describedby="Dental Sealants:">Porcelain Veneers:</summary>
                    <div className="accordion-content">
                      <p>Dental-grade porcelain veneers can flawlessly correct and cover cracks, stains, dental chips, and gaps in your smile. The procedure is completed in just two visits to our office, which will leave you with a dramatically improved smile.</p>
                    </div>
                  </details>
                  <details>
                  <summary aria-describedby="Dental Sealants:">Cosmetic Bonding:</summary>
                    <div className="accordion-content">
                      <p>Cosmetic Bonding is similar to Veneers in the sense that the procedure can dramatically improve the appearance of your teeth. Cosmetic bonding uses tooth-colored composite resin to conceal dental discoloration, fill in cracks and chips in tooth enamel and other flaws. The procedure is completed in just a single office visit.</p>
                    </div>
                  </details>
                  <details>
                  <summary aria-describedby="Dental Sealants:">Invisalign:</summary>
                    <div className="accordion-content">
                      <p>Unsightly wire or metal braces are not the only option to straighten your teeth. We offer Invisalign – an  invisible solution to misaligned teeth. We utilize a series of clear aligner trays to seamlessly correct both bite alignment and tooth spacing, leaving you with a straight and beautiful smile.</p>
                    </div>
                  </details>
                  <details>
                  <summary aria-describedby="Dental Sealants:">Smile Makeover:</summary>
                    <div className="accordion-content">
                      <p>If you’re looking to transform your smile, then we can work with you to create a tailored smile makeover plan that incorporates many restorative and cosmetic dentistry solutions. Our smile makeover service will leave you with the flawless smile of your dreams. </p>
                    </div>
                  </details>

                </div>
              </div>
            </div>
            <div className="col-lg-12 text-left">
              <div className="columns">
                <h3 className ="section-3-header">Are Cosmetic Dental Procedures Covered By My Insurance?</h3>
                <p>Every patient’s situation is different. We would love to discuss payment options with you. Please call our office at <a href="tel:3176439434">317-643-9434</a>.</p>
              </div>
            </div>
          </Container>
        </section>
    </Layout>
  )
}

export default Cosmetic2Page

export const query = graphql`
  query Cosmetic2PageQuery {
    hero: file(name: { eq: "hero-image" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    pd_cta_background: file(name: { eq: "placeholder" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    hero_background: file(name: { eq: "about-us-hero" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    product1: file(relativePath: { eq: "images/marco-pharma.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    father_and_daughter_brushing_teeth_in_bathroom: file(name: { eq: "young-woman-with-sunglasses-smiling" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    close_up_of_woman_using_dental_floss_from_pack_in_bathroom: file(name: { eq: "cute-little-girl-smiling-fun-happiness-retro-concept" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
        twitter
        
      }
    }
  }
`
